<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
    >{{message}}</vs-alert>
    <br />
    <form>
      <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        v-model="user.email"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first('email') }}</span>
      <br>
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        :type="passwordFieldType"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        v-model="user.password"
        ref="password"
        class="w-full"
      />
      <span
                  toggle="#password"
                  :class="passwordShowIcon"
                  @click="switchPasswordVisibility"
                ></span>
      <span class="text-danger text-sm">{{ errors.first('password') }}</span>
    </form>

    <div class="flex flex-wrap justify-between my-5">
      <router-link to="/forgot-password">Forgot Password?</router-link>
    </div>

    <div class="flex flex-wrap justify-between mb-3">
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { authMixin } from "../../../mixins/authMixin"
export default {
  mixins:[authMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loginStatus: false,
      showDismissibleAlert: false,
      message: "",
      passwordFieldType: "password",
      passwordShowIcon: "fa fa-fw fa-eye field-icon toggle-password"
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    ...mapActions("users", ["loginJWT"]),
     loginJWT() {
      const payload = {
        userDetails: this.user,
      };
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.$vs.loading();
          const response = await this.adminLogin(this.user)
          this.$vs.loading.close();
          if(response.message && response.message.code == 200){
              this.$router.replace("/admin/dashboard")
          }
          else{
              this.showDismissibleAlert = true;
              this.message = "Invalid Email/Password"
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: "Login error.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
          }

        }
      });
    },
    forgotPassword() {
      this.$router.push("forgotPassword");
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },

    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordShowIcon =
        this.passwordShowIcon === "fa fa-fw fa-eye field-icon toggle-password"
          ? "fa fa-fw fa-eye-slash field-icon toggle-password"
          : "fa fa-fw fa-eye field-icon toggle-password";
    }
  },
  clickIcon() {
    console.log("ICON CLICKED")
  }
};
</script>

